import { Component } from '@angular/core';

@Component({
  selector: 'app-geolocalizacion-de-empleados',
  templateUrl: './geolocalizacion-de-empleados.component.html',
  styleUrls: ['./geolocalizacion-de-empleados.component.scss']
})
export class GeolocalizacionDeEmpleadosComponent {

}
