import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-policy-page',
  templateUrl: './privacy-policy-page.component.html',
  styleUrls: ['./privacy-policy-page.component.scss']
})
export class PrivacyPolicyPageComponent implements OnInit {

  date: Date;

  constructor() 
  { 
    this.date = new Date('2024-03-17T03:24:00')
  }

  ngOnInit(): void {
  }

}
