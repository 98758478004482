import { Component, OnInit, Renderer2 } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-calculadora-de-horas',
  templateUrl: './calculadora-de-horas.component.html',
  styleUrls: ['./calculadora-de-horas.component.scss']
})
export class CalculadoraDeHorasComponent implements OnInit {

  hourlyRate: number | null = null;
  result: string = '';
  days: string[] = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
  displayedDays: string[] = this.days;
  hours: { [key: string]: { start: string, end: string } } = {};

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private renderer: Renderer2
  ) {
    this.days.forEach(day => {
      this.hours[day] = { start: '', end: '' };
    });
  }

  ngOnInit(): void {
    this.setTitleAndMetaTags();
  }

  setTitleAndMetaTags() {
    this.titleService.setTitle('Calculadora de hora de trabajo');
    this.metaService.addTags([
      { name: 'description', content: 'Descubre cómo la calculadora de horas de trabajo de Cucú puede optimizar la gestión de horarios laborales, proporcionando precisión, transparencia y cumplimiento legal.' },
      { name: 'keywords', content: 'calculadora de hora de trabajo, calcular horas trabajadas, contar horas trabajadas, cálculo horas laborales, contar las horas de trabajo, software de control de asistencia' },
    ]);
    this.setCanonicalURL('https://cucutime.com/calculadora-horas-trabajadas');
  }

  setCanonicalURL(url: string) {
    const existingLink: HTMLLinkElement = this.renderer.selectRootElement('link[rel="canonical"]', true);
    if (existingLink) {
      this.renderer.removeChild(document.head, existingLink);
    }
    const link: HTMLLinkElement = this.renderer.createElement('link');
    this.renderer.setAttribute(link, 'rel', 'canonical');
    this.renderer.setAttribute(link, 'href', url);
    this.renderer.appendChild(document.head, link);
  }

  calculateDailyTotal(day: string): string {
    const start = this.hours[day].start;
    const end = this.hours[day].end;
    if (start && end) {
      const [startHour, startMinute] = start.split(':').map(Number);
      const [endHour, endMinute] = end.split(':').map(Number);

      let startDateTime = new Date();
      startDateTime.setHours(startHour, startMinute);

      let endDateTime = new Date();
      endDateTime.setHours(endHour, endMinute);

      let diff = (endDateTime.getTime() - startDateTime.getTime()) / (1000 * 60 * 60);
      if (diff < 0) diff += 24;

      return diff.toFixed(2);
    }
    return '0';
  }

  weeklyTotal(): { hours: string, minutes: string } {
    let totalHours = 0;
    this.displayedDays.forEach(day => {
      const dailyTotal = parseFloat(this.calculateDailyTotal(day));
      if (!isNaN(dailyTotal)) {
        totalHours += dailyTotal;
      }
    });
    const hours = Math.floor(totalHours);
    const minutes = Math.round((totalHours - hours) * 60);
    return { hours: hours.toString(), minutes: minutes.toString() };
  }

  calculate(): void {
    const total = this.weeklyTotal();
    const totalHours = parseFloat(total.hours) + parseFloat(total.minutes) / 60;
    const rate = this.hourlyRate !== null ? this.hourlyRate : 0;
    const totalPay = totalHours * rate;
    this.result = `Total a pagar: $${totalPay.toFixed(2)}`;
  }

  updateTotal(): void {
    this.weeklyTotal();
  }

  print(): void {
    window.print();
  }

  printForm(): void {
    const printContents = document.getElementById('calculadora-form')?.innerHTML;
    const originalContents = document.body.innerHTML;
  
    if (printContents) {
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload(); // Para recargar la página y restaurar el contenido original
    }
  }

  exportCsv(): void {
    const rows = this.displayedDays.map(day => {
      return [day, this.hours[day].start, this.hours[day].end, this.calculateDailyTotal(day)].join(',');
    });
    const csvContent = "data:text/csv;charset=utf-8," + ["Días,Hora de Inicio,Hora de Fin,Total Horas", ...rows].join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "horas_trabajadas.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
