<div class="container">
    <div class="app-download-inner">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="app-download-content">
                    <span class="sub-title">DESCARGAR APP</span>
                    <!-- <h2>Let's Get Your Free Copy From Apple and Play Store</h2> -->
                    <h2>Obtenga la aplicación de Play Store</h2>
                    <p><strong>Cucú</strong> revoluciona la gestión de tiempo con tecnología de reconocimiento facial y geolocalización, permitiendo un registro rápido y seguro desde cualquier dispositivo.
                     Disponible en Google Play, es perfecta para equipos móviles, mejorando la eficiencia y la productividad. Instala <strong>Cucú</strong> y optimiza la coordinación y colaboración de tu equipo.
                    </p>
                    <div class="btn-box">
                        <a href="https://play.google.com/store/apps/details?id=com.cucutime&pcampaignid=web_share" class="playstore-btn" target="_blank">
                            <img src="assets/img/play-store.png" alt="Cucú Play Store" width="27" height="30">
                            Get It On
                            <span>Google Play</span>
                        </a>
                        <a href="https://apps.apple.com/us/app/cuc%C3%BA-time/id6502964114?platform=iphone" class="applestore-btn" target="_blank">
                            <img src="assets/img/apple-store.png" alt="Cucú Apple Store">
                            Download on the
                            <span>Apple Store</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="app-download-image" data-aos="fade-up">
                    <img src="assets/img/app/app-img3.webp" loading="lazy" alt="app cucú control de asistencia" width="957" height="1083">
                </div>
            </div>
        </div>
        <div class="shape5"><img src="assets/img/shape/shape4.webp" alt="shape4" width="121" height="363"></div>
        <div class="lines">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
        </div>
    </div>
</div>