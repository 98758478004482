<div class="features-area pt-100 pb-75">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="features-box">
                    <div class="icon">
                        <i class="ri-smartphone-line"></i>
                    </div>
                    <h2>Amigable al Usuario</h2>
                    <p>Navegación intuitiva y diseño accesible para todo tipo de usuarios.</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="features-box">
                    <div class="icon bg2">
                        <i class="ri-cloud-line"></i>
                    </div>
                    <h2>Servicio en la nube</h2>
                    <p>Accede fácilmente sin instalar nada en tu equipo.</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="features-box">
                    <div class="icon bg3">
                        <i class="ri-fingerprint-line"></i>
                    </div>
                    <h2>Privacidad Protegida</h2>
                    <p>Seguridad y confidencialidad en cada paso.</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-6 col-md-4">
                <div class="features-box">
                    <div class="icon bg4">
                        <i class="ri-flashlight-line"></i>
                    </div>
                    <h2>Actualizaciones constantes</h2>
                    <p>Tu app siempre al día, sin costo adicional.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="overview-area">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-xl-6 col-lg-12 col-md-12 p-0">
                <div class="overview-content">
                    <span class="sub-title">APP</span>
                    <h2>Registro horario fácil y seguro</h2>
                    <p>Descubre la manera más eficiente de gestionar el registro horario de tu empresa con nuestra aplicación móvil.
                    Diseñada tanto para celulares como para tablets, nuestra app te permite marcar entradas y salidas con geolocalización y
                    foto,
                    asegurando un seguimiento preciso y confiable del tiempo de trabajo.</p>
                    <ul class="features-list">
                        <li>
                            <div class="icon">
                                <i class="ri-download-cloud-2-line"></i>
                            </div>
                            <h3>Free Download App</h3>
                            <p>Just log in with your mail account from play store and using whatever you want for your business purpose.</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="ri-award-line"></i>
                            </div>
                            <h3>Trusted and Reliable</h3>
                            <p>Most provabily best you can trust on it, just log in with your mail account from play store and using whatever you want for your business.</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="ri-cloud-line"></i>
                            </div>
                            <h3>Cloud Storage</h3>
                            <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy.</p>
                        </li>
                    </ul>
                    <div class="btn-box">
                        <a routerLink="/sign-in" class="default-btn">Start Free Trial</a>
                        <a routerLink="/features-2" class="link-btn">See All Features</a>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 p-0">
                <div>
                    <img src="assets/img/app_1.png" alt="overview">
                </div>
            </div>
        </div>
    </div>
</div> -->

<app-homefive-about></app-homefive-about>