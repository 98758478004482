<app-navbar-style-one></app-navbar-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Como funciona</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Como funciona</li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="how-it-works-area ptb-100">
    <div class="container">
        <div class="how-it-works-content">
            <div class="number">1</div>
            <div class="row m-0">
                <div class="col-lg-3 col-md-12 p-0">
                    <div class="box">
                        <h3>Paso 1</h3>
                        <span>Instala la app Cucutime</span>
                    </div>
                </div>
                <div class="col-lg-9 col-md-12 p-0">
                    <div class="content">
                        <h4>Control de asistencia desde dispositivos móviles Android e iOS</h4>
                        <p>Cucutime permite a tus empleados registrar su asistencia <strong>desde cualquier smartphone o tablet</strong>, ya sea que trabajen en la oficina, en casa, o en movimiento. Nuestra app, compatible con Android e iOS, facilita un registro rápido y preciso, con funciones avanzadas como <strong>geolocalización</strong> y <strong>reconocimiento facial</strong>. Descargar e instalar Cucutime es fácil, asegurando que tu equipo pueda comenzar a marcar su asistencia en minutos, garantizando así la fiabilidad y seguridad de cada registro.</p>
                        <img src="assets/img/how-it-works/registrar-asistencia.webp" alt="Registrar asistencia desde cualquier smartphone o tablet" width="930" height="520">
                    </div>
                </div>
            </div>
        </div>
        
        <div class="how-it-works-content">
            <div class="number">2</div>
            <div class="row m-0">
                <div class="col-lg-3 col-md-12 p-0">
                    <div class="box">
                        <h3>Paso 2</h3>
                        <span>Envío en tiempo real</span>
                    </div>
                </div>
                <div class="col-lg-9 col-md-12 p-0">
                    <div class="content">
                        <h4>Monitoreo en tiempo real para una gestión eficiente</h4>
                        <p>Las marcas de asistencia se transmiten <strong>instantáneamente</strong> a nuestro software de gestión, brindándote un control en tiempo real y siempre actualizado sobre la asistencia de tu personal. Esto te permite tomar decisiones informadas y <strong>gestionar tu equipo de manera más eficiente y precisa</strong>.</p>
                        <img src="assets/img/how-it-works/asistencia_en_tiempo_real.webp" alt="Monitoreo en tiempo real para una gestión eficiente" width="930" height="520">
                    </div>
                </div>
            </div>
        </div>
        <div class="how-it-works-content">
            <div class="number">3</div>
            <div class="row m-0">
                <div class="col-lg-3 col-md-12 p-0">
                    <div class="box">
                        <h3>Paso 3</h3>
                        <span>Gestión y reportes</span>
                    </div>
                </div>
                <div class="col-lg-9 col-md-12 p-0">
                    <div class="content">
                        <h4>Gestión de horarios y reportes detallados</h4>
                        <p>Simplifica la <strong>asignación de horarios y turnos en tu empresa</strong>. Con Cucutime, puedes generar y <strong>descargar reportes detallados en Excel</strong> que incluyen la asistencia y las horas trabajadas, facilitando el análisis de la productividad. Además, nuestra solución te ayuda a reducir la impuntualidad y <strong>mejorar la puntualidad en el trabajo</strong>, promoviendo un ambiente laboral más eficiente y organizado.</p>
                        <img src="assets/img/how-it-works/reportes.webp" alt="Gestión de horarios y reportes detallados" width="930" height="520">
                    </div>
                </div>
            </div>
        </div>

        <div class="how-it-works-content">
            <div class="number">4</div>
            <div class="row m-0">
                <div class="col-lg-3 col-md-12 p-0">
                    <div class="box">
                        <h3>Paso 4</h3>
                        <span>Notificaciones en tiempo real</span>
                    </div>
                </div>
                <div class="col-lg-9 col-md-12 p-0">
                    <div class="content">
                        <h4>Ausencias, llegadas tardes, fichajes fuera de zona</h4>
                        <p>Mantente informado al instante sobre los <strong>empleados que llegan tarde</strong>. Con Cucutime, recibirás notificaciones inmediatas sobre ingresos tardíos, ausencias y cualquier intento de fraude durante el fichaje. Recupera el <strong>control total de la gestión de horarios de tu empresa</strong> y asegúrate de que todo el equipo cumpla con sus responsabilidades, optimizando así la eficiencia y la integridad en el lugar de trabajo.</p>
                        <img src="assets/img/how-it-works/notificaciones.webp" alt="Ausencias, llegadas tardes, fichajes fuera de zona" width="930" height="520">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-feedback-style-three></app-feedback-style-three>

<app-footer-style-one></app-footer-style-one>