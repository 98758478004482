import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Contacto } from '../models/contacto.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactoService {

  private urlApi = `${environment.apiUrl}/Notificaciones/Contacto-Cucu`;

  constructor(private httpClient: HttpClient) { }

  PostNuevoContacto(contacto: Contacto) {
    return this.httpClient.post(this.urlApi, contacto);
  }
}
