export class Pais {
    public nombre: string = '';
}

export const PAISES: Pais[] = [
    { nombre: 'Uruguay' },
    { nombre: 'Argentina' },
    { nombre: 'Bolivia' },
    { nombre: 'Chile' },
    { nombre: 'Colombia' },
    { nombre: 'Costa Rica' },
    { nombre: 'Cuba' },
    { nombre: 'Ecuador' },
    { nombre: 'El Salvador' },
    { nombre: 'España' },
    { nombre: 'Guatemala' },
    { nombre: 'Honduras' },
    { nombre: 'México' },
    { nombre: 'Nicaragua' },
    { nombre: 'Panamá' },
    { nombre: 'Paraguay' },
    { nombre: 'Perú' },
    { nombre: 'Puerto Rico' },
    { nombre: 'República Dominicana' },
    { nombre: 'Venezuela' }
]