<div class="video-area">
    <div class="container">
        <div class="video-box">
            <img src="assets/img/video/video-img3.webp" alt="Video Cucú" width="1100" height="659">
            <button class="video-btn popup-video" (click)="openPopup()" aria-label="Reproducir video"><i class="ri-play-line"></i></button>
            <div class="shape">
                <img class="shape1" src="assets/img/shape/shape1.webp" alt="shape1" width="99" height="185">
                <img class="shape2" src="assets/img/shape/shape2.webp" alt="shape2" width="149" height="185">
            </div>
        </div>
    </div>
</div>

<!-- Video Popup -->
<div class="video-popup" *ngIf="isOpen">
    <div class="popup-inner">
        <iframe src="https://www.youtube.com/embed/EgQiIlZr8ak?si=k3S-TD-jsD3cVt5H" title="Cómo funciona nuestro sistema" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <button (click)="closePopup()" type="button" class="close-btn">
            <i class="ri-close-line"></i>
        </button>
    </div>
</div>