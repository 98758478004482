import { Component, OnInit, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { Contacto } from 'src/app/core/models/contacto.model';
import { ContactoService } from 'src/app/core/services/contacto.service';
import { NotificacionService } from 'src/app/core/services/notificacion.service';
import { RecaptchaService } from 'src/app/core/services/recaptcha.service';
import { PAISES, Pais } from 'src/app/core/models/pais.model';
import { EMPLEADOS, Empleado } from 'src/app/core/models/empleado.model';
import { AnalyticsService } from 'src/app/core/services/analytics.service';


@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
  contactoForm!: FormGroup;
  model: Contacto = new Contacto();
  loading: boolean = false;
  submitted: boolean = false;
  recaptcha: string | undefined;
  isBrowser: boolean = false;
  paises: Pais[] = PAISES;
  empleados: Empleado[] = EMPLEADOS;

  @ViewChild('captchaElem') captchaElem: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private contactoService: ContactoService,
    private recaptchaService: RecaptchaService,
    private notificacionService: NotificacionService,
    private analytics: AnalyticsService
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (this.isBrowser) {
      this.recaptcha = recaptchaService.getRecaptchaSiteId();
    }
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      this.initForm();
    }
  }

  initForm() {
    this.contactoForm = new FormGroup({
      nombre: new FormControl('', Validators.required),
      nombreEmpresa: new FormControl('', Validators.required),
      pais: new FormControl('Seleccione País', Validators.required),
      industria: new FormControl(''),
      cantidadEmpleados: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.email]),
      telefono: new FormControl('', Validators.required),
      descripcion: new FormControl('', Validators.required),
      asunto: new FormControl('', Validators.required),
      recaptcha: new FormControl('', Validators.required),
    });

    this.contactoForm.get('nombre')?.valueChanges.subscribe(data => this.model.nombre = data);
    this.contactoForm.get('email')?.valueChanges.subscribe(data => this.model.email = data);
    this.contactoForm.get('telefono')?.valueChanges.subscribe(data => this.model.telefono = data);
    this.contactoForm.get('descripcion')?.valueChanges.subscribe(data => this.model.descripcion = data);
    this.contactoForm.get('nombreEmpresa')?.valueChanges.subscribe(data => this.model.nombreEmpresa = data);
    this.contactoForm.get('pais')?.valueChanges.subscribe(data => this.model.pais = data);
    this.contactoForm.get('industria')?.valueChanges.subscribe(data => this.model.industria = data);
    this.contactoForm.get('cantidadEmpleados')?.valueChanges.subscribe(data => this.model.cantidadEmpleados = data);
  }

  enviarMensaje() {
    this.submitted = true;
    if (!this.contactoForm.valid) {
      return;
    }
    this.loading = true;    
    this.contactoService.PostNuevoContacto(this.model).subscribe({
      next: (data) => {
        this.notificacionService.showSuccess('¡Gracias por comunicarte!', 'Nos pondremos en contacto contigo a la brevedad.');
        this.contactoForm.reset();
        this.submitted = false;
        this.loading = false;
        this.analytics.trackConversion({event_label: 'contact_form', value: 100});
        if (this.isBrowser && this.captchaElem) {
          this.captchaElem.reloadCaptcha();
        }
      },
      error: (error) => {
        this.notificacionService.showError('Error al enviar contacto', 'Tuvimos problemas al enviar el formulario, intente nuevamente.');
        this.loading = false;
        this.submitted = false;
        if (this.isBrowser && this.captchaElem) {
          this.captchaElem.reloadCaptcha();
        }
      }
    });
  }

  // Recaptcha Functions
  handleReset() { /* ... */ }
  handleExpire() { /* ... */ }
  handleLoad() { /* ... */ }
  handleSuccess(event: any) { /* ... */ }
}