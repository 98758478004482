export class Empleado {
    nombre: string | undefined;
}

export const EMPLEADOS: Empleado[] = [
    { nombre: '1 - 10' },
    { nombre: '10 - 25' },
    { nombre: '25 - 50' },
    { nombre: '50 - 100' },
    { nombre: 'Más de 100' }
]