<app-navbar-style-one></app-navbar-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Contacto</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contacto</li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" width="121" height="362" alt="image"></div>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Contacta con nosotros</h2>
            <p>¿Estás listo para llevar tu negocio al siguiente nivel? No te pierdas la oportunidad de explorar nuestra solución de primera mano. 
                <strong>Solicita ahora una demo gratuita</strong> y descubre cómo <strong>Cucú</strong> puede transformar tu manera de trabajar.</p>
            <!-- <p>La información recogida a través de este formulario de contacto será utilizada exclusivamente con el fin
                de
                proporcionarle la respuesta o los servicios solicitados.</p> -->
        </div>
        <div class="contact-form">
            <form [formGroup]="contactoForm" (ngSubmit)="enviarMensaje()">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="name" class="form-control" formControlName="nombre"
                                placeholder="Nombre">
                            <div class="input-error"
                                *ngIf="submitted && contactoForm.get('nombre')?.hasError('required')">
                                <small>
                                    El campo Nombre es requerido
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="form-group">
                            <input type="email" name="email" class="form-control" formControlName="email"
                                placeholder="Correo electrónico">
                            <div class="input-error"
                                *ngIf="submitted && contactoForm.get('email')?.hasError('required')">
                                <small>
                                    El campo Correo Electrónico es requerido
                                </small>
                            </div>
                            <div class="input-error"
                                *ngIf="submitted && !contactoForm.get('email')?.hasError('required') && contactoForm.get('email')?.hasError('email')">
                                <small>
                                    Ingrese un formato de correo válido
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="empresa" class="form-control" formControlName="nombreEmpresa"
                                placeholder="Nombre Empresa">
                            <div class="input-error"
                                *ngIf="submitted && contactoForm.get('nombreEmpresa')?.hasError('required')">
                                <small>
                                    El campo Nombre Empresa es requerido
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="form-group">
                                <select class="form-control" formControlName="pais">
                                    <option value="Seleccione País" disabled selected hidden>País</option>
                                    <option *ngFor="let pais of paises" [value]="pais.nombre">
                                        {{pais.nombre}}
                                    </option>
                                </select>
                                <div class="input-error"
                                    *ngIf="submitted && (contactoForm.get('pais')?.hasError('required') || contactoForm.get('pais')?.value === 'Seleccione País')">
                                    <small>
                                        El campo País es requerido
                                    </small>
                                </div>

                            <!-- <input type="email" name="pais" class="form-control" formControlName="pais"
                                placeholder="País"> -->
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="industria" class="form-control" formControlName="industria"
                                placeholder="Industria">
                            <div class="input-error"
                                *ngIf="submitted && contactoForm.get('industria')?.hasError('required')">
                                <small>
                                    El campo Industria es requerido
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="form-group">
                            
                            <select class="form-control" formControlName="cantidadEmpleados">
                                <option value="" disabled selected hidden>Cantidad Empleados</option>
                                <option *ngFor="let empleado of empleados" [value]="empleado.nombre">
                                    {{empleado.nombre}}
                                </option>
                            </select>
                            <!-- <input type="number" name="cantidadEmpleados" class="form-control"
                                formControlName="cantidadEmpleados" placeholder="Cantidad Empleados"> -->
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="telefono" class="form-control" formControlName="telefono"
                                placeholder="Teléfono">
                            
                            <div class="input-error"
                                *ngIf="submitted && contactoForm.get('telefono')?.hasError('required')">
                                <small>
                                    El campo Teléfono es requerido
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="msg_subject" class="form-control" formControlName="asunto"
                                placeholder="Asunto">
                            <div class="input-error"
                                *ngIf="submitted && contactoForm.get('asunto')?.hasError('required')">
                                <small>
                                    El campo Asunto es requerido
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                            <textarea name="message" class="form-control" cols="30" rows="6"
                                formControlName="descripcion" placeholder="Escribir un mensaje..."></textarea>
                            <div class="input-error"
                                *ngIf="submitted && contactoForm.get('descripcion')?.hasError('required')">
                                <small>
                                    El campo Descripción es requerido
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                        <div *ngIf="isBrowser">
                            <ngx-recaptcha2 #captchaElem [siteKey]="recaptcha" (reset)="handleReset()"
                                (expire)="handleExpire()" (load)="handleLoad()" (success)="handleSuccess($event)"
                                [useGlobalDomain]="false" formControlName="recaptcha">
                            </ngx-recaptcha2>
                        </div>
                        <button type="submit" class="default-btn my-4 my-sm-0">
                            <span class="spinner-grow spinner-grow-sm mr" role="status" aria-hidden="true"
                                *ngIf="loading"></span>
                            <span><i class='bx bx-paper-plane'></i>{{loading ? 'Enviado Mensaje' : 'Enviar
                                Mensaje'}}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- <div class="maps">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46660.669043361966!2d-76.17429939609431!3d43.03529129888566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d9f3b8019f2991%3A0x58d5929e21a62e5!2sDinosaur%20Bar-B-Que!5e0!3m2!1sen!2sbd!4v1593527523138!5m2!1sen!2sbd"></iframe>
    </div> -->
</div>

<app-contact-info></app-contact-info>

<app-footer-style-one></app-footer-style-one>