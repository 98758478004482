<div class="footer-area">
    <div class="container">
        <div class="footer-content">
            <a routerLink="/" class="logo">
                <img src="assets/img/cucu_vertical_dos_colores.webp" alt="logo" width="104" height="104">
            </a>
            <ul class="social-links">
                <li><a href="https://www.facebook.com/Softtero/" target="_blank" aria-label="Visítanos en Facebook"><i class="ri-facebook-fill"></i></a></li>
                <li><a href="https://twitter.com/SoftTero" target="_blank" aria-label="Síguenos en Twitter"><i class="ri-twitter-fill"></i></a></li>
                <li><a href="https://www.linkedin.com/company/softtero/" target="_blank" aria-label="Conéctate con nosotros en LinkedIn"><i class="ri-linkedin-fill"></i></a></li>
                <!-- <li><a href="#" target="_blank"><i class="ri-messenger-fill"></i></a></li> -->
                <!-- <li><a href="#" target="_blank"><i class="ri-github-fill"></i></a></li> -->
            </ul>
            <ul class="navbar-nav">
                <li class="nav-item"><a routerLink="" [ngxScrollTo]="'top'" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a></li>
                <li class="nav-item"><a routerLink="" [ngxScrollTo]="'video'" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Acerca de</a></li>
                <li class="nav-item"><a routerLink="" [ngxScrollTo]="'features'" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Funcionalidades</a></li>
<!--                 <li class="nav-item"><a routerLink="" [ngxScrollTo]="'screenshots'" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Capturas</a></li>
 -->                <li class="nav-item"><a routerLink="" [ngxScrollTo]="'pricing'" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Precios</a></li>
                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>
            </ul>
            <p class="copyright">© Cucú es un desarrollo de <a href="https://whitemonkey.io/" target="_blank">WhiteMonkey</a></p>
        </div>
    </div>
</div>