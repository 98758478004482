<app-navbar-style-one></app-navbar-style-one>

<div class="error-area pb-100">
    <div class="container">
        <div class="error-content">
            <img src="assets/img/error.png" alt="image">
            <!-- <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p> -->
            <p>La página que está buscando ha sido quitada, su nombre cambió, o temporalmente está inhabilitada.</p>
            <a routerLink="/" class="default-btn">Volver a Inicio</a>
        </div>
    </div>
</div>

<!-- <div class="app-download-area pb-100">
    <app-download-app-style-two></app-download-app-style-two>
</div> -->

<!-- <app-footer-style-one></app-footer-style-one> -->

<app-footer-style-one></app-footer-style-one>