<app-navbar-style-one></app-navbar-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Política de Privacidad</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Política de Privacidad</li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="privacy-policy-area ptb-100">
    <div class="container">
        <div class="privacy-policy-content">
            <h3>General</h3>
            <p>Última actualización: {{date | date: 'dd/MM/yyyy'}}</p>
            <p>En <b>Cucú</b>, una de nuestras principales prioridades es la privacidad de nuestros usuarios. Este documento de Política de Privacidad contiene tipos de información que son recopilados y registrados por nuestra aplicación y cómo los usamos.</p>
            <p>Si tienes preguntas adicionales o necesitas más información sobre nuestra Política de Privacidad, no dudes en contactarnos.</p>
            
            <h3>Información que recopilamos</h3>
            
            <p>La información personal que se pide proporcionar, y las razones por las que se pide proporcionarla, se te harán claras en el punto en el que te pidamos que proporciones tu información personal.</p>
            <p>Si decides usar nuestra aplicación, entonces aceptas la recopilación y uso de información en relación con esta política. La Información Personal que recopilamos se utiliza para proporcionar y mejorar el servicio. No usaremos ni compartiremos tu información con nadie, excepto como se describe en esta Política de Privacidad.</p>

            <h6>Información de registro</h6>
            <p>Para una experiencia mejorada al usar nuestra Aplicación, es posible que te solicitemos que nos proporciones cierta información de identificación personal, que incluye, pero no se limita a:</p>

            <ul>
                <li>Número de funcionario de empresa</li>
                <li>ID de empresa</li>
                <li>Acceso a la cámara del dispositivo</li>
                <li>Acceso a la ubicación del dispositivo para geolocalización</li>
            </ul>

            <h3>Cómo usamos tu información</h3>
            <p>Usamos la información que recopilamos de diversas maneras, incluyendo para:</p>

            <ul>
                <li>Proveer, operar y mantener nuestra aplicación</li>
                <li>Mejorar, personalizar y expandir nuestra aplicación</li>
                <li>Entender y analizar cómo utilizas nuestra aplicación</li>
                <li>Desarrollar nuevos productos, servicios, características y funcionalidades</li>
                <li>Comunicarnos contigo, directamente o a través de uno de nuestros socios, incluyendo para atención al cliente, para proporcionarte actualizaciones e otra información relacionada con la aplicación, y con fines de marketing y promoción</li>
                <li>Enviar correos electrónicos</li>
                <li>Encontrar y prevenir fraudes</li>
            </ul>

            <h3>Archivo de Log</h3>
            <p><b>Cucú</b> sigue un procedimiento estándar de uso de archivos de registro. Estos archivos registran a los usuarios cuando visitan aplicaciones. Todas las empresas de alojamiento hacen esto y forman parte del análisis de los servicios de alojamiento. La información recopilada por los archivos de registro incluye direcciones de protocolo de internet (IP), tipo de navegador, proveedor de servicios de Internet (ISP), fecha y hora, páginas de referencia/salida, y posiblemente el número de clics. Estos no están vinculados a ninguna información que sea personalmente identificable. El propósito de la información es para analizar tendencias, administrar el sitio, rastrear el movimiento de los usuarios en el sitio web, y recopilar información demográfica.</p>
        
            <h3>Seguridad de tus datos personales</h3>
            <p>La seguridad de tus datos personales es importante para nosotros, pero recuerda que ningún método de transmisión por Internet o método de almacenamiento electrónico es 100% seguro. Si bien nos esforzamos por usar medios comercialmente aceptables para proteger tus datos personales, no podemos garantizar su seguridad absoluta.</p>
        
            <h3>Cambios en esta política de privacidad</h3>
            <p>Podemos actualizar nuestra Política de Privacidad de vez en cuando. Por lo tanto, se te recomienda revisar esta página periódicamente para cualquier cambio. Te notificaremos de cualquier cambio publicando la nueva Política de Privacidad en esta página.</p>

            <h3>Contáctanos</h3>
            <p>Si tienes alguna pregunta o sugerencia sobre nuestra Política de Privacidad, no dudes en <a [routerLink]="[ '/contact' ]">contactarnos.</a></p>

        </div>
    </div>
</div>

<!-- <div class="app-download-area pb-100">
    <app-download-app-style-two></app-download-app-style-two>
</div> -->

<app-footer-style-one></app-footer-style-one>