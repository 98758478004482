import { Component, OnInit, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-navbar-style-two',
    templateUrl: './navbar-style-two.component.html',
    styleUrls: ['./navbar-style-two.component.scss']
})
export class NavbarStyleTwoComponent implements OnInit {
    classApplied = false;
    isSticky: boolean = false;

    constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

    ngOnInit(): void {}

    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        if (isPlatformBrowser(this.platformId)) {
            const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
            this.isSticky = scrollPosition >= 50;
        }
    }
}