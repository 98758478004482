// analytics.service.ts
import { Injectable } from '@angular/core';

declare global {
    interface Window {
        dataLayer: any[];
        gtag: (...args: any[]) => void;
    }
}

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {

    constructor() {
        this.loadScript();
    }

    loadScript() {
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-1001883058';
        document.head.appendChild(script);

        script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag(...args: any[]) {
                window.dataLayer.push(args);
            }
            window.gtag = gtag;  // Assign the function to window.gtag
            window.gtag('js', new Date());
            window.gtag('config', 'AW-1001883058');
        }
    }

    trackConversion(data: any) {
        window.gtag('event', 'conversion', { ...data });
    }
}