import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing-style-two',
  templateUrl: './pricing-style-two.component.html',
  styleUrls: ['./pricing-style-two.component.scss']
})
export class PricingStyleTwoComponent implements OnInit {

  selectedUsers: number = 10;
  basePrice: number = 28; // Precio base para 10 usuarios
  pricePerUser: number = 1.25; // Precio adicional por cada usuario después de 10
  calculatedPrice: number = this.basePrice + this.pricePerUser * (this.selectedUsers - 10);


  constructor() { }

  ngOnInit(): void {
  }

  calculatePrice() {
    if (this.selectedUsers <= 10) {
      this.calculatedPrice = this.basePrice;
    } else {
      this.calculatedPrice = this.basePrice + this.pricePerUser * (this.selectedUsers - 10);
    }
  }

}
