import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-registro-dispositivo',
  templateUrl: './registro-dispositivo.component.html',
  styleUrls: ['./registro-dispositivo.component.scss']
})
export class RegistroDispositivoComponent implements OnInit, AfterViewInit {

  constructor(private _activatedRoute: ActivatedRoute) { }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this._activatedRoute.queryParamMap
      .subscribe((params) => {
        if (params.get('codigo') && params.get('funcionario')) {
          window.open(`exp+cucutime://?NroEmpresa=${params.get('codigo')}&Codigo=${params.get('funcionario')}`, '_self');
        }
      });
  }

}
