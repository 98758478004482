import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificacionService {

  constructor(private toastr: ToastrService) { }

  showSuccess(texto: string, titulo: string) {
    this.toastr.success(titulo, texto, {
      timeOut: 10000
    });
  }

  showError(texto: string, titulo: string) {
    this.toastr.error(titulo, texto, {
      timeOut: 10000
    });
  }

  showInfo(texto: string, titulo: string) {
    this.toastr.info(titulo, texto, {
      timeOut: 10000
    });

  }

  showWarning(texto: string, titulo: string) {
    this.toastr.warning(titulo, texto, {
      timeOut: 10000
    });
  }
}
