import { Component, OnInit, Renderer2 } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-details-page',
  templateUrl: './blog-details-page.component.html',
  styleUrls: ['./blog-details-page.component.scss']
})
export class BlogDetailsPageComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta, private renderer: Renderer2) { }

  ngOnInit(): void {
    this.setTitleAndMetaTags();
    this.preloadImages([
      'assets/img/blog/LaRevolucióndelSoftwaredeControlHorario-1x.webp',
      'assets/img/blog/LaRevolucióndelSoftwaredeControlHorario-2x.webp',
      'assets/img/blog/LaRevolucióndelSoftwaredeControlHorario-3x.webp'
    ]);
  }

  setTitleAndMetaTags() {
    this.titleService.setTitle('La Revolución del Software de Control Horario: Cucú');
    this.metaService.addTags([
      { name: 'description', content: 'Descubre cómo el software de control horario Cucú puede optimizar la gestión de horarios laborales, con características avanzadas como integración con dispositivos biométricos, móviles, geolocalización y reconocimiento facial.' },
      { name: 'keywords', content: 'software de control horario, gestión de horarios laborales, Cucú, dispositivos biométricos, geolocalización, reconocimiento facial' },
    ]);
    this.setCanonicalURL('https://cucutime.com/blog/software-control-horario');
  }

  setCanonicalURL(url: string) {
    // Buscar y eliminar cualquier enlace canónico existente
    const existingLink: HTMLLinkElement = this.renderer.selectRootElement('link[rel="canonical"]', true);
    if (existingLink) {
      this.renderer.removeChild(document.head, existingLink);
    }

    // Crear y añadir un nuevo enlace canónico
    const link: HTMLLinkElement = this.renderer.createElement('link');
    this.renderer.setAttribute(link, 'rel', 'canonical');
    this.renderer.setAttribute(link, 'href', url);
    this.renderer.appendChild(document.head, link);
  }

  preloadImages(urls: string[]) {
    urls.forEach(url => {
      const img = new Image();
      img.src = url;
    });
  }
}