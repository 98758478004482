<div class="feedback-area bg-gradient-color ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="feedback-section-title mb-5">
                    <span class="sub-title">CLIENT REVIEWS</span>
                    <h2>Lo que nuestros clientes dicen sobre nosotros</h2>
                    <a href="https://whitemonkey.io" target="_blank" class="btn btn-primary mt-4">Sobre Nosotros</a>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="feedback-slides-two">
                    <owl-carousel-o [options]="feedbackSlides">
                        <ng-template carouselSlide>
                            <div class="single-feedback-box">
                                <div class="client-info">
                                    <div class="d-flex align-items-center">
                                        <img src="assets/img/reviews/DeyvisPerez.webp" alt="user">
                                        <div class="title">
                                            <h3>Deyvis Perez</h3>
                                            <span>Gerente General</span>
                                        </div>          
                                        <img src="assets/img/paises/argentina.webp" alt="Argentina" class="country-flag">                            
                                    </div>
                                </div>                               
                                <p>Para nosotros, <b>Cucutime</b> ha sido el mejor aliado para asegurarnos de que nuestros empleados cumplen en tiempo y forma con nuestras 
                                    políticas de horario de trabajo. Las herramientas de geolocalización y el biométrico son muy modernas y nos han proporcionado una 
                                    gran tranquilidad al poder contar con ellas. Estamos muy contentos con el desempeño y la confiabilidad de estas funcionalidades.
                                    Agradecemos su dedicación en crear un producto tan efectivo y esperamos seguir contando con su excelente soporte.</p>
                                <div class="rating d-flex align-items-center justify-content-between">
                                    <h4>ADN Disfit</h4>
                                    <div>
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-fill"></i>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="single-feedback-box">
                                <div class="client-info">
                                    <div class="d-flex align-items-center">
                                        <img src="assets/img/reviews/GermanSalvatierra.webp" alt="user">
                                        <div class="title">
                                            <h3>Germán Salvatierra</h3>
                                            <span>Directivo</span>
                                        </div>
                                        <img src="assets/img/paises/uruguay.webp" alt="Uruguay" class="country-flag">
                                    </div>
                                </div>
                                <p><b>Cucutime</b> ha sido una verdadera solución para nuestro club. Es un software que nos ha brindado una gestión centralizada de las marcas, 
                                    manejo de reportes detallados y una interfaz muy intuitiva y moderna. Podemos destacar varios aspectos del software como facilidad 
                                    de uso, eficiencia, pero sin dudas que lo principal y destacable es el soporte y asistencia por parte de WhiteMonkey. 
                                    La implementación de todo el sistema fue un proceso planificado y sin contratiempos, lo que nos dio gran seguridad. </p>
                                <div class="rating d-flex align-items-center justify-content-between">
                                    <h4>ACJ San José</h4>
                                    <div>
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-fill"></i>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <!-- <ng-template carouselSlide>
                            <div class="single-feedback-box">
                                <div class="client-info">
                                    <div class="d-flex align-items-center">
                                        <img src="assets/img/user/user3.jpg" alt="user">
                                        <div class="title">
                                            <h3>James Andy</h3>
                                            <span>Moodle Developer</span>
                                        </div>
                                    </div>
                                </div>
                                <p>"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type."</p>
                                <div class="rating d-flex align-items-center justify-content-between">
                                    <h5>Responsive Design</h5>
                                    <div>
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-line"></i>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="single-feedback-box">
                                <div class="client-info">
                                    <div class="d-flex align-items-center">
                                        <img src="assets/img/user/user4.jpg" alt="user">
                                        <div class="title">
                                            <h3>David Warner</h3>
                                            <span>Python Developer</span>
                                        </div>
                                    </div>
                                </div>
                                <p>"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type."</p>
                                <div class="rating d-flex align-items-center justify-content-between">
                                    <h5>Design Quality</h5>
                                    <div>
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-half-line"></i>
                                    </div>
                                </div>
                            </div>
                        </ng-template> -->
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</div>